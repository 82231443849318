import { render, staticRenderFns } from "./AsideMenu.vue?vue&type=template&id=6877ea1b&scoped=true"
import script from "./AsideMenu.vue?vue&type=script&lang=js"
export * from "./AsideMenu.vue?vue&type=script&lang=js"
import style0 from "./AsideMenu.vue?vue&type=style&index=0&id=6877ea1b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6877ea1b",
  null
  
)

export default component.exports