import axios from "axios"
import Vue from 'vue';
import config from "@/config/index"
const _this = Vue.prototype

// axios.defaults.baseURL = 'http://192.168.31.53:8080/hc-admin'
// axios.defaults.baseURL = 'http://192.168.31.77:8080/hc-admin'
axios.defaults.baseURL = config.baseUrl


axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("hc-token")
    config.headers.Token = token

    return config
}, (err) => {
    return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
    const { code,msg } = response.data
    if (code === 401) {
        window.location.href = "#/login"
        _this.$message.error('token过期，请重新登录');
        return response.data
    }
    if(code !==0 ){
        _this.$message.error(msg);
        return response.data
    }

    return response.data
}, (err) => {
    return Promise.reject(err)
})
