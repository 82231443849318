<template>
    <el-menu :default-active="activeMenu" router class="el-menu-vertical-demo" unique-opened>
        <template v-for="(item, index) in menuList">
            <el-submenu v-if="item.children && item.children.length" :index="index + ''" :key="item.id">
                <template #title>
                    <i :class="item.icon"></i>
                    <span>{{ item.name }}</span>
                </template>
                <template v-for="child in item.children">
                    <el-menu-item :key="child.name" :index="child.url">
                        <i :class="child.icon"></i>
                        <span>{{ child.name }}</span>
                    </el-menu-item>
                </template>
            </el-submenu>
            <el-menu-item v-else :key="item.id" :index="item.url">
                <i :class="item.icon"></i>
                <span>{{ item.name }}</span>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script>
export default {
    data() {
        return {
            menuList: [],
            activeMenu: this.$route.path,
        }
    },
    mounted() {
        this.getMenuList()
    },
    methods: {
        getMenuList() {
            this.$axios({
                url: "/sys/menu/select",
                method: "GET"
            }).then(res => {
                if (res.code == 0) {
                    const router = res.data
                    this.menuList = router
                    if(router[0].children.length){
                        this.$router.push(router[0].children[0].url)
                    }else{
                        this.$router.push(router[0].url)
                    }
                }
            })
        },

    },
    watch: {
        '$route.path': function (newPath) {
            this.activeMenu = newPath;
        }
    },
}

</script>

<style scoped>
.el-menu {
    height: 100%;
}
</style>